import { useState, createContext, useEffect } from "react";
export const UserContext = createContext();
export function ContextComponent({ children }) {
  const [userData, setUserData] = useState({});
  const setData=(data)=>{
   setUserData(data)
    localStorage.setItem("email", data.email); //for mainttain session of user
   localStorage.setItem("api_key", data.api_key);
  }
  
  return <UserContext.Provider value={{userData,setData}} >{children}</UserContext.Provider>;
}
