import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../css/Header.css";

export default function Header() {
  const history = useNavigate();
  const [user, setUser] = useState();

  const handleLogout = () => {
    localStorage.clear();
    setUser("");
    history("/");
  };

  useEffect(() => {
    const loggedInUser = localStorage.getItem("email");
    if (loggedInUser) {
      setUser(loggedInUser);
    }
  }); 

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-transparent header">
        <div className="container" >
          <div className="p-2">
            <span className="brand-logo"></span>
            <span className="navbar-brand"> App<span style={{color:"#1bbdbd"}}>ICE</span></span>
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavAltMarkup"
            aria-controls="navbarNavAltMarkup"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
        </div>
        <div className="collapse navbar-collapse  " id="navbarNavAltMarkup" >
          <div className="navbar-nav navlinks " >
            <Link
              to={"/dashboard"}
              className="nav-link  mb-2 link-text"
              aria-current="page"
              data-bs-toggle="collapse"
            >
              Home
            </Link>
            {!user ? (
              <Link to={"#"} className="nav-link mb-2 link-text">
                Login
              </Link>
            ) : (
              (
                <Link
                  to={"/"}
                  className="nav-link mb-2 link-text"
                  onClick={handleLogout}
                >
                  Logout
                </Link>
              )
            )}
          </div>
        </div>
      </nav>
     
    </>
  );
}
