import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./Components/Login";
import Header from "./Components/Header";
import Dashboard from "./Components/Dashboard";
import { ContextComponent } from "./context/context";

function App() {
  return (
    <BrowserRouter>
      <ContextComponent>
        <Header />
        <Routes>
          <Route path="/" element={<Login />}></Route>
          <Route path="/dashboard" element={<Dashboard />}></Route>
        </Routes>
      </ContextComponent>
    </BrowserRouter>
  );
}

export default App;
