import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "../css/Login.css";
import { UserContext } from "../context/context";
import { Button } from "./Button";

export default function Login(props) {
  const { title, titleColor, children, background, color, ...rest } = props;

  // ERROR MESSAGE
  const context = useContext(UserContext);
  const [errorMessage, setErrorMessage] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const history = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post(`${process.env.REACT_APP_CDNURL}/v1/login`, { email: email, password: password })
      .then((res) => {
        if (res.data.success) {
          //set data gloablly so that all components can use it (using context api)
          context.setData(res.data);
          history("/dashboard");
        }
      })
      .catch((err) => {
        console.log(err.message);
        setErrorMessage("Kindly Enter Valid Credentials!");
        setTimeout(() => {
          setErrorMessage("");
        }, 2000);
      });
  };
  useEffect(() => {
    localStorage.getItem("email") ? history("/dashboard") : "";
  });
  return (
    <section
      className="container-fluid d-flex justify-content-center login-section-container  align-items-center  "
      style={{ minHeight: "90vh" }}
    >
      <div
        className="container  login-container  p-5"
        style={{ background: background, color: color }}
      >
        {errorMessage && (
          <div className="alert alert-danger" role="alert">
            {errorMessage}
          </div>
        )}

        <div className="brand" style={{ color: titleColor }}>
          {title}
        </div>
        <form action="">
          <div className="mb-3">
            <label className="form-label">Email address</label>
            <input
              type="email"
              className="form-control login-input"
              placeholder="Enter your email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <label className="form-label input-lg">Password</label>
            <input
              type="password"
              className="form-control login-input"
              placeholder="Enter your password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          <div className="mt-4 mb-3">
            {/* <input
              type="submit"
              className="btn btn-primary m-0 mt-3 login-button"
              value="Login"
              style={{ width: "100%"  }}
              onClick={handleSubmit}
              
            /> */}
            <Button
              onClick={handleSubmit}
              backgroundColor="#1D8888"
              label={"Login"}
              size="large"
              primary={true}
            ></Button>
          </div>
        </form>
      </div>
    </section>
  );
}
//default props for login component
Login.defaultProps = {
  title: "Welcome to AppICE",
  titleColor: "black",
  background: "white",
  color: "white",
};
