import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "../css/dashboard.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function Dashboard() {
  const [selectedHour, setSelectedHour] = useState(5);
  const [mode, setMode] = useState("Summary"); // Default mode is "Summary"
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedPlatform, setSelectedPlatform] = useState('android');

  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [data, setData] = useState([]);
  const [cmps, setCmps] = useState([]);
  const [filterCmps, setFilterCmps] = useState([]);
  const [app, isApp] = useState(false);
  const [status, iscmpstate] = useState(false);
  const [campData, setCampDetails] = useState([{ rowName: "", rowValue: "" }]);
  const [cmp, isCamp] = useState(false);
  const [isEditActive, setIsActive] = useState(false);
  const [cid, setCid] = useState("");
  const [user, setUser] = useState();
  const [scrollSearch, setScrollSearch] = useState("");
  const history = useNavigate();
  const [loading, setLoading] = useState(false);

  const [appName, setAppName] = useState("Select any of the app");
  const [campName, setCampName] = useState("Select any of the Campaign");
  const [cmpStatus,setCmpStatus] = useState('Select the Campaign Status')
  const [formattedDate,setformattedDate]= useState(new Date().toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' }).split('/').reverse().join('_'))

  const handleModeChange = (newMode) => {
    setMode(newMode);

    if(newMode==='Daywise'){

      //fetch daxta based on modechange   
      fetchDataBasedOnMode(selectedPlatform,formattedDate,selectedHour);
    }else{
      campselection(cid,campName)
    }


  };
const funsetSelectedHour = (hour)=>{
  setSelectedHour(hour)
  fetchDataBasedOnMode(selectedPlatform,formattedDate,hour)
}
  const funsetSelectedDate=(date)=>{
    setSelectedDate(date)
    const formateDate = date.toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' }).split('/').reverse().join('_')
    setformattedDate(formateDate)
    fetchDataBasedOnMode(selectedPlatform,formateDate,selectedHour)

  }
 const funsetSelectedPlatform = (platform)=>{
  
     setSelectedPlatform(platform)

     //fetch data based on platform change
     fetchDataBasedOnMode(platform,formattedDate,selectedHour)
 }

 const fetchDataBasedOnMode = (platform,date,hour) => {
  setCampDetails([])
  const details = {
      formattedDate:date,
      selectedPlatform:platform,
      selectedHour:hour,
      cid:cid
    }


// Convert details object to query parameters
const queryParams = new URLSearchParams(details);

isCamp(false);

    const appId = localStorage.getItem("appId");

    axios.get(`${process.env.REACT_APP_CDNURL}/v1/getcmpdaywiseDetails/${appId}/${queryParams}`)
    .then((res) => {
      if (res.data.data) {
        isCamp(true);
      
        setCampDetails((prevCampData) => [...prevCampData, ...res.data.data]);

      }
    })
    .catch((err) => {
      
      setErrorMessage("Error while Selecting a campaign.!");
      setTimeout(() => {
        setErrorMessage("");
      }, 2000);


    });

  };
  const UpdateCampaignDetails = (e) => {
    e.preventDefault();
    const appId = localStorage.getItem("appId");
    axios
      .post(`${process.env.REACT_APP_CDNURL}/v1/updateCmpdata`, {
        campData: campData,
        appId: appId,
        cid: cid,
        mode:mode,
        formattedDate:formattedDate,
        selectedHour:selectedHour,
        selectedPlatform,selectedPlatform
      })
      .then((res) => {
        if (res.data.success) {
          setIsActive(false); //changing again the edit mode to false
          setSuccessMessage("Campaign Updated Successfully.!");
        setTimeout(() => {
          setSuccessMessage("");
        }, 2000);
        }
      })
      .catch((err) => {
        setErrorMessage("Error While Updating a Campaign.!");
        setTimeout(() => {
          setErrorMessage("");
        }, 2000);
      });
  };
  const handleFormChange = (index, event) => {
    let data = [...campData];
    data[index]["rowValue"] = event.target.value;
  };

  const handleInputScroll = (e) => {
    setFilterCmps(
      cmps.filter((cmp) => {
        return cmp.cname ? cmp.cname.includes(e.target.value) : false;
      })
    );
  };

  //handling Edit
  const handleEditClick = () => {
    setIsActive(true);
  };

  const setCmpType = (status)=>{
    isCamp(false);
    setCampName("Select any of the Campaign");
    setCmpStatus(status)
    iscmpstate(true)
    setLoading(true);
    const appId = localStorage.getItem("appId");
    axios
      .get(`${process.env.REACT_APP_CDNURL}/v1/getcmps/${appId}/${status}`)
      .then((res) => {
        //incase data is right
        if (res.data.data) {
          setLoading(false);
          setCmps(res.data.data);
          setFilterCmps(res.data.data);
          localStorage.setItem("appId", appId);
        }
      })
      .catch((err) => {
        setErrorMessage("Error Selecting an App.!");
        setTimeout(() => {
          setErrorMessage("");
        }, 2000);
      });
  }
  const campselection = (cid, cmpname) => {
    setCampName(cmpname);
    isCamp(false);
    setCid(cid);
    const appId = localStorage.getItem("appId");
    axios
      .get(`${process.env.REACT_APP_CDNURL}/v1/getcmpdata/${appId}/${cid}`)
      .then((res) => {
        if (res.data.data) {
          setMode('Summary')  
          isCamp(true);
          setCampDetails(res.data.data);
        }
      })
      .catch((err) => {
        setErrorMessage("Error while Selecting a campaign.!");
        setTimeout(() => {
          setErrorMessage("");
        }, 2000);
      });
  };

  const appSelection = (id, appname) => {
    setAppName(appname);
    isApp(true);
    localStorage.setItem("appId", id);
    iscmpstate('')
    setCmpStatus('Select the Campaign Status')
  };
  const getData = () => {
    const apiKey = localStorage.getItem("api_key");
    axios
      .get(`${process.env.REACT_APP_CDNURL}/v1/getData/${apiKey}`)
      .then((res) => {
        setData(res.data.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    const loggedInUser = localStorage.getItem("email");
    if (loggedInUser) {
      setUser(loggedInUser);
      getData();
    } else {
      //redirect to login page
      history("/");
    }
  }, []);

  return (
    <>
      {errorMessage && (
        <div className="alert alert-danger" role="alert">
          {successMessage}
        </div>
      )}
       {successMessage && (
        <div className="alert alert-success" role="alert">
          {successMessage}
        </div>
      )}
      <div className="container">
        <div className="row m-4">
          <div className=" dropdown col-md-4">
            <a
              className="btn btn-dark mt-2 w-100 dropdown-toggle"
              role="button"
              id="dropdownMenuLink"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {appName}
            </a>

            <ul
              className="dropdown-menu  w-100 "
              style={{
                maxHeight: "70vh",
                minWidth: "250px",
                overflowY: "scroll",
              }}
              aria-labelledby="dropdownMenuLink"
            >
              {data.map((app) => {
                return (
                  <li key={app.id}>
                    <a
                      className="dropdown-item"
                      onClick={() => appSelection(app.id, app.name)}
                    >
                      {app.name}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>

          {app ?(<div className=" dropdown col-md-3">
            <a
              className="btn btn-dark mt-2 w-100 dropdown-toggle"
              role="button"
              id="dropdownMenuLink"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {cmpStatus}
            </a>

            <ul
              className="dropdown-menu  w-100 "
              style={{
                maxHeight: "70vh",
                minWidth: "250px",
                overflowY: "scroll",
              }}
              aria-labelledby="dropdownMenuLink"
            >
              {['Active','Draft','Past'].map((status) => {
                return (
                  <li key={status}>
                    <a
                      className="dropdown-item"
                      onClick={() => setCmpType(status)}
                    >
                      {status}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>):(<> </>)}
          {loading && (
          <div className="col-md-1">
            <i className="fas fa-spinner fa-pulse fa-2xl"></i>
          </div>
          )}
          {status && !loading ? (
            //container 
            <div className="dropdown col-md-4  ">
              <a
                className="btn btn-dark w-100 mt-2 dropdown-toggle"
                role="button"
                id="dropdownMenuLink"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {campName}
              </a>

              <ul
                className="dropdown-menu w-100 "
                style={{
                  maxHeight: "70vh",
                  minWidth: "250px",
                  overflowY: "scroll",
                }}
                aria-labelledby="dropdownMenuLink"
              >
                <input
                  type="text"
                  onChange={handleInputScroll}
                  className="form-control scroll-input form-control-sm"
                  placeholder="Search here"
                />
                {filterCmps.map((cmp) => {
                  return (
                    <li key={cmp._id}>
                      <a
                        className="dropdown-item"
                        onClick={() => campselection(cmp._id, cmp.cname)}
                      >
                        {cmp.cname}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
          ) : (
            <> </>
          )}
        </div>
      </div>

      {cmp ? (
        <div className="edit-outer-container container " style={{height:'auto'}}>
          <div
            className="container d-flex flex-column edit-container  align-items-center "
            id="campDetails"
          >
            {/* Switch between "Summary" and "Daywise" modes */}
            <div className="form-group row   d-flex align-items-center DetailRow ">
              <div className="col-lg-4 text-label">
                <label>
                  Mode
                  {/* {field.rowName} */}
                </label>
              </div>
              <div className="col-lg-8">

                <div className="form-check form-switch">
                  <input

                    className="form-check-input"
                    type="checkbox"
                    id="modeSwitch"
                    checked={mode === "Daywise"}
                    onChange={() =>
                      handleModeChange(mode === "Daywise" ? "Summary" : "Daywise")
                    }
                  />
                  <label className="form-check-label" htmlFor="modeSwitch">
                    {mode === "Summary" ? "Summary" : "Daywise"}
                  </label>
                </div>

              </div>
            </div>
            {mode === "Daywise" && (
              <>

                {/* DATE PICKER START  */}
                <div className="form-group row   d-flex align-items-center DetailRow ">
                  <div className="col-lg-4 text-label">
                    <label>
                      Select Date
                    </label>
                  </div>
                  <div className="col-lg-8">
                    <DatePicker
                      className="form-control edit-input"
                      selected={selectedDate}
                      onChange={(date) => funsetSelectedDate(date)}
                    />
                  </div>
                </div>

                {/* // DATE PICKER END */}

                {/* Hour Picker Start */}
                 <div className="form-group row   d-flex align-items-center DetailRow ">
                  <div className="col-lg-4 text-label">
                    <label>
                      Select Hour
                    </label>
                  </div>
                  
                
                  <div className=" dropdown col-lg-8">
                    <a 
                    
                      className=" form-control edit-input w-100 mt-2 dropdown-toggle"
                      role="button"
                      id="dropdownMenuLink"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      { selectedHour }
                    </a>

                    <ul
                      className="dropdown-menu w-100 "
                      style={{
                        maxHeight: "30vh",
                        minWidth: "250px",
                        overflowY: "scroll",
                      }}
                      aria-labelledby="dropdownMenuLink"
                    >

                      {[...Array(24).keys()].map((hour) => {
                        return (
                          <li key={hour}>
                            <a
                              className="dropdown-item form-control edit-input"
                              onClick={(e) => funsetSelectedHour(hour)}
                            >
                              {hour}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                    {/* <select 
                     className="form-control edit-input"
                     value={selectedHour}
                     onChange={(e) => funsetSelectedHour(e.target.value)}
                     style={{
                      maxHeight: "70vh",
                      minWidth: "250px",
                      overflowY: "scroll",
                    }}
                   >
                     {[...Array(24).keys()].map((hour) => (
                       <option key={hour} value={hour}>
                         {hour}
                       </option>
                     ))}
                   </select> */}
                  </div>




                 </div>
                {/* Hour Picker End */}

                {/* PLATFORM SELECTION START */}
                <div className="form-group row d-flex align-items-center DetailRow">
                  <div className="col-lg-4 text-label">
                    <label>Select Platform</label>
                  </div>
                  <div className="col-lg-8">
                    <div className="d-flex form-control edit-input">

                      <div className="platform-input" style={{ paddingRight: "30px" }}>
                        <input type="checkbox" id="androidCheckbox" checked={selectedPlatform === "android"} onChange={() => funsetSelectedPlatform("android")} />
                        <label htmlFor="androidCheckbox">Android </label>
                      </div>

                      <div className="platform-input" style={{ paddingRight: "30px" }}>
                        <input type="checkbox" id="iOSCheckbox" checked={selectedPlatform === "ios"} onChange={() => funsetSelectedPlatform("ios")} />
                        <label htmlFor="iOSCheckbox">iOS </label>
                      </div>

                      <div className="platform-input">
                        <input type="checkbox" id="webCheckbox" checked={selectedPlatform === "web"} onChange={() => funsetSelectedPlatform("web")} />
                        <label htmlFor="webCheckbox">Web </label>
                      </div>


                    </div>
                  </div>
                </div>
                {/* PLATFORM SELECTION END */}

                
              </>
            )}
            {campData.map((field, index) => {
              return (
                <div key={index} className="form-group row   d-flex align-items-center DetailRow ">
                  <div className="col-lg-4 text-label">
                    <label>
                      {" "}
                      {field.rowName}
                    </label>
                  </div>
                  <div className="col-lg-8">
                    <input
                      type="text"
                      className="form-control edit-input"
                      onChange={(event) => handleFormChange(index, event)}
                      id=""
                      defaultValue={field.rowValue}
                      disabled={field.rowName=="Campaign Id" || !isEditActive ||field.rowName=="Campaign Name" || field.rowName=="Start Date" || field.rowName=="End Date"}
                    />
                  </div>
                </div>
              );
            })}
          </div>
          <div className="container pb-1 d-flex flex-column  align-items-center">
            <div className="row DetailRow  ">
              <div className="clearfix col-lg-4 "> </div>
              <div className="col-4 ">
                <button
                  style={{ width: "100%" }}
                  onClick={handleEditClick}
                  id="cmp_edit"
                  className="btn btn-secondary"
                >
                  Edit
                </button>
              </div>
              <div className="col-4 ">
                <button
                  style={{
                    display: isEditActive ? "block" : "none",
                    width: "100%",
                  }}
                  id="cmp_save"
                  type="submit"
                  onClick={UpdateCampaignDetails}
                  className="btn btn-success"
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
